import React, { useEffect, useState } from "react";
import img from "../../assets/images/nr1.jpg";
import { notification } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MTNLogo from "../../assets/images/mtn.png";
import DataProvider from "../../services";
import Colors from "../../constants/Colors";
import images from "../../assets/images/poweredbymtnwhite.png";
import Steps from "../../components/elements/steps";
import UserRegister from "../../components/sections/user-register";
import med from "../../assets/images/logo.png";
import SelectPlan from "../../components/sections/select-plan";
import Firebase from "../../core/firebase";
import { convertToLocalFormat, firebaseErrorMessage } from "../../utils";
import { useAppStore } from "../../store";
import { v4 as uuidv4 } from "uuid";
import { ConfirmationResult } from "firebase/auth";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type NotificationType = "success" | "info" | "warning" | "error";

export default function Register() {
  const query = useQuery();
  const { setToken, setUser } = useAppStore();
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [signupMethod, setSignupMethod] = useState<string | null>("email");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [referralCode, setReferralCode] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult | null>(null);

  async function tracking() {
    const source = query.get("source");
    const campaign = query.get("campaign");

    if (source && campaign) {
      // Store the data in local storage and backend.
      localStorage.setItem("@source", source);
      localStorage.setItem("@campaign", campaign);
      const payload = { source, campaign, trackingId: uuidv4() };
      const { data } = await DataProvider.createNewTracking(payload);
      if (data) {
        localStorage.setItem("@tracking", JSON.stringify(data));
      }
    }
  }

  // https://mcare.medpharma.care/sign-up?source=google&campaign=ads

  useEffect(() => {
    const source = localStorage.getItem("@source");
    const campaign = localStorage.getItem("@campaign");
    if (!source && !campaign) {
      tracking();
    }
    // eslint-disable-next-line
  }, []);

  const handleSignupMethodChange = (value: string) => {
    setSignupMethod(value);
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    message: string
  ) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  async function onSubmit() {
    if (name.length < 3) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Full name is required, type your full name!"
      );
    }

    if (signupMethod === "email" && email.length < 3) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Email must not be empty, type your email address!"
      );
    }

    if (
      signupMethod === "phone" &&
      (!phoneNumber || phoneNumber.length !== 10)
    ) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Phone number must not be empty, type your phone number!"
      );
    }

    if (password.length < 6) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Password must be 6 or more characters!"
      );
    }

    if (signupMethod === "phone") {
      if (!isOtpSent) {
        sendOtp();
      } else {
        verifyOtp();
      }
    }

    if (signupMethod === "email") {
      setLoading(true);
      await Firebase.signupWithEmail(email, password)
        .then(async (result) => {
          const user = result.user;
          await Firebase.updateUserProfile(user, {
            displayName: name,
          }).then(async () => {
            setToken({ token: await user.getIdToken() });
            DataProvider.setToken(await user.getIdToken());
            const newUsr = await DataProvider.updateProfile({
              fullname: name,
              email: email,
              referralCode: referralCode.length > 3 ? referralCode : null,
            });
            setUser({ ...newUsr.data, timenow: new Date() });
            const tracking = localStorage.getItem("@tracking");
            if (tracking) {
              const payload = await JSON.parse(tracking);
              const { data } = await DataProvider.updateTracking({
                source: payload.source,
                campaign: payload.campaign,
                trackingId: payload.trackingId,
                userId: newUsr.data.id,
              });
              if (data) {
                localStorage.setItem("@tracking", JSON.stringify(data));
              }
            }
            setLoading(false);
            navigate("/pick-plan");
          });
        })
        .catch(async (error) => {
          if (error.code === "auth/email-already-in-use") {
            await Firebase.loginWithEmail(email, password)
              .then(async (result) => {
                const user = result.user;
                DataProvider.setToken(await user.getIdToken());
                setToken({ token: await user.getIdToken() });
                const profile = await DataProvider.getProfile();
                setUser({ ...profile.data, timenow: new Date() });
                setLoading(false);
                navigate("/pick-plan");
              })
              .catch(async (error) => {
                if (error.code === "auth/wrong-password") {
                  setLoading(false);
                  return openNotificationWithIcon(
                    "error",
                    "Error",
                    "Email already registered. Kindly sign in!"
                  );
                }
                setLoading(false);
                return openNotificationWithIcon(
                  "error",
                  "Error",
                  "An error occurred during sign-up, try again."
                );
              });
          }
          const errMsg: string = firebaseErrorMessage(error);
          openNotificationWithIcon("error", "Error", errMsg);
          setLoading(false);
        });
    }
  }

  const sendOtp = async () => {
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = Firebase.recaptchaVerifier();
        await window.recaptchaVerifier.render(); // Render reCAPTCHA
      }
      setLoading(true);
      const confirmationResult = await Firebase.loginWithPhoneNumber(
        phoneNumber,
        window.recaptchaVerifier
      );
      setConfirmationResult(confirmationResult);
      setIsOtpSent(true); // OTP sent, change flow
      setLoading(false);
      openNotificationWithIcon(
        "success",
        "OTP Sent",
        "Please check your phone for the verification code."
      );
    } catch (error: any) {
      const e: string = firebaseErrorMessage(error);
      openNotificationWithIcon("error", "Error", e);
      setLoading(false);
    }
  };

  const verifyOtp = async () => {
    if (otp.length < 6) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Please enter the 6-digit OTP!"
      );
    }
    setLoading(true);
    try {
      if (!confirmationResult) return;
      const result = await confirmationResult.confirm(otp);
      const user = result.user;

      // Update user's profile with their full name
      await Firebase.updateUserProfile(user, { displayName: name });
      // Get the user token
      const idToken = await user.getIdToken();
      setToken({ token: idToken });
      await DataProvider.setToken(idToken);

      // Create user profile in the database
      const newUsr = await DataProvider.updateProfile({
        fullname: name,
        phoneNumber: convertToLocalFormat(phoneNumber),
        referralCode: referralCode.length > 3 ? referralCode : null,
      });

      // Update the app state with the new user
      setUser({ ...newUsr.data, timenow: new Date() });
      // Handle any tracking information if needed
      const tracking = localStorage.getItem("@tracking");
      if (tracking) {
        const payload = JSON.parse(tracking);
        const { data } = await DataProvider.updateTracking({
          source: payload.source,
          campaign: payload.campaign,
          trackingId: payload.trackingId,
          userId: newUsr.data.id,
        });

        if (data) {
          localStorage.setItem("@tracking", JSON.stringify(data));
        }
      }

      setLoading(false);
      navigate("/pick-plan");
    } catch (error: any) {
      if (error.message === "SESSION_EXPIRED") {
        openNotificationWithIcon(
          "error",
          "Error",
          "Session expired. Sign up again!"
        );
        setLoading(false);
        return;
      }
      if (
        error.message === "Authentication failed" ||
        (error.response &&
          error.response?.data &&
          error.response?.data.message &&
          error.response?.data.message === "Authentication failed")
      ) {
        openNotificationWithIcon(
          "error",
          "Error",
          "Failed to verify your Otp. Request again!"
        );
        setLoading(false);
        return;
      }
      const err = firebaseErrorMessage(error);
      openNotificationWithIcon("error", "Error", err);
      setLoading(false);
    }
  };

  const steps = [
    {
      title: "Create account",
      content: (
        <UserRegister
          onSubmit={onSubmit}
          setCurrent={setCurrent}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          email={email}
          setEmail={setEmail}
          name={name}
          setName={setName}
          password={password}
          setPassword={setPassword}
          loading={loading}
          setLoading={setLoading}
          setReferralCode={setReferralCode}
          referralCode={referralCode}
          error={error}
          setError={setError}
          signupMethod={signupMethod}
          setSignupMethod={setSignupMethod}
          handleSignupMethodChange={handleSignupMethodChange}
          otp={otp}
          setOtp={setOtp}
          sendOtp={sendOtp}
          isOtpSent={isOtpSent}
          setIsOtpSent={setIsOtpSent}
        />
      ),
    },
    {
      title: "Subscription",
      content: <SelectPlan />,
    },
    {
      title: "Billing",
      content: "Billing information",
    },
  ];

  return (
    <div className="w-full h-full">
      <div
        className="min-h-screen lg:hidden flex flex-col justify-center items-center w-full bg-cover bg-[#1A94ECB5] bg-blend-overlay"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="min-h-[60%] w-full rounded-t-3xl bg-white flex flex-col justify-center items-center pb-4 pt-8">
          <div className="w-full flex flex-col justify-start items-center">
            <Steps setCurrent={setCurrent} current={current} items={steps} />
            <div className="w-full">{steps[current].content}</div>
          </div>
        </div>

        <div className="flex justify-evenly mt-3">
          <img src={med} className="h-6 mx-4" alt="" />
          <img src={MTNLogo} className="h-6" alt="" />
        </div>
      </div>

      <div className="h-screen w-full lg:flex hidden justify-between items-center">
        <div
          className="h-full pb-24 flex flex-col justify-end items-center w-[50%] bg-cover bg-[#1A94ECB5] bg-blend-overlay"
          style={{ backgroundImage: `url(${img})` }}
        >
          <Link to="/" className="absolute top-6 left-4">
            {/* <img src={logo} className="h-12 w-36" alt="mcare" /> */}
            <img
              src={images}
              alt="care"
              className="md:h-20 h-12  rounded-l-lg"
            />
          </Link>
          <h1 className="text-5xl font-bold text-white">
            Your gateway to urgent care
          </h1>
          <p className="text-white font-semibold text-3xl my-2">
            With just a single tap
          </p>
        </div>

        <div className="h-full w-[47%] flex flex-col justify-start pt-5 items-center">
          {error && error.length > 0 && (
            <div className="justify-center items-center bg-red-100 rounded-lg p-2 my-2 border-red-300">
              <div style={{ fontSize: 12, color: Colors.deepgray }}>
                {error}
              </div>
            </div>
          )}
          <div className="w-full flex flex-col justify-start items-center ">
            <Steps setCurrent={setCurrent} current={current} items={steps} />
            <div className="w-full">{steps[current].content}</div>
          </div>
        </div>
      </div>
      <div id="recaptcha-container"></div>
      {contextHolder}
    </div>
  );
}
