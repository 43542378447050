import React, { useState } from "react";
import Nav from "./nav";
import img from "../assets/mb.jpg";
import img2 from "../assets/mob.jpg";
// import kobi from "../../../../assets/images/mascot.png";
import { useNavigate } from "react-router-dom";
import ChatButton from "../../../../components/cards/message-card/chatButton";
// import { FloatButton } from "antd";
import ChatInterface from "../../../../components/sections/chatai";
// import { MessageOutlined } from "@ant-design/icons";

declare global {
  interface Window {
    ebanqo_widget: any;
  }
}

// const showEbanqoWidget = () => {
//   const closed_class = "ebanqo-instant-chat-wrapper ebanqo-button-slide-out";
//   const open_class = "ebanqo-instant-chat-wrapper ebanqo-button-slide open";

//   const chatWrapper = document.querySelector(
//     ".ebanqo-widget-wrapper .ebanqo-instant-chat-wrapper"
//   );

//   if (chatWrapper) {
//     chatWrapper.setAttribute("class", open_class);
//     if (chatWrapper.className === open_class) {
//       chatWrapper.setAttribute("class", closed_class);
//     }

//     if (chatWrapper.className === closed_class) {
//       chatWrapper.setAttribute("class", open_class);
//     }
//   }
// };

export default function Header() {
  const navigate = useNavigate();
  const [isChatOpen, setIsChatOpen] = useState(false);

  return (
    <>
      <header
        className="w-full lg:block hidden xl:h-screen min-h-[50vh] bg-cover bg-center p-4 bg-no-repeat"
        style={{ backgroundImage: `url(${img})` }}
      >
        <Nav />
        <div className="flex lg:justify-between justify-center items-end lg:items-center my-8 w-full min-h-[40vh]">
          <p className="sm:hidden text-xl font-bold text-center">
            Healthcare That Comes to You on your Schedule.
          </p>
          <div className="large=view   p-4 lg:block hidden w-[34%]">
            <h1 className="xl:text-7xl lg:text-6xl md:text-6xl sm:text-5xl text-3xl text-black font-bold">
              Healthcare That Comes to You on your Schedule.
            </h1>
          </div>
          <div className="opacity-0 pointer-events-none">
            <ChatButton />
          </div>

          <div className="">
            {/* <div className="fixed bottom-12 right-36 bg-white shadow-md px-4 py-2 rounded-full border border-gray-300 text-sm flex items-center animate-fadeIn">
              <span className="text-gray-700">
                How are you today? I’m here to assist you with all your health
                needs.
              </span>
            </div> */}
            {/* <FloatButton.Group
              trigger="hover"
              type="primary"
              style={{ insetInlineEnd: 94 }}
              className="transform scale-125"
              icon={<MessageOutlined />}
            >
              <button
                onClick={showEbanqoWidget}
                className="rounded-lg bg-orange-500 w-24 p-3 mb-2 text-white hover:bg-orange-600"
              >
                💬 chat us
              </button>
              <button
                onClick={() => setIsChatOpen(true)}
                className="rounded-lg bg-red-500 w-24 p-3 text-white hover:bg-red-600 transition-all flex items-center justify-center"
              >
                <img
                  src={kobi}
                  alt="care"
                  className="h-6 w-6 rounded-lg mr-2"
                />
                Kobikuul
              </button>
            </FloatButton.Group> */}
          </div>
          {isChatOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6 relative">
                <button
                  onClick={() => setIsChatOpen(false)}
                  className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                >
                  ✖
                </button>
                <ChatInterface />
              </div>
            </div>
          )}
        </div>
      </header>

      <header
        className="w-full sm:block lg:hidden hidden min-h-[50vh] bg-cover bg-center p-4"
        style={{ backgroundImage: `url(${img2})` }}
      >
        <Nav />
        <div className="medium-view   flex justify-between items-center my-8 w-full min-h-[40vh] ">
          <div className="p-4 md:w-[40%] w-[50%]">
            <h1 className="xl:text-7xl lg:text-6xl md:text-6xl sm:text-5xl text-4xl text-black font-bold">
              Healthcare That Comes to You on your Schedule..
            </h1>
          </div>

          <div className="">
            {/* <div className="fixed bottom-12 right-36 bg-white shadow-md px-4 py-2 rounded-full border border-gray-300 text-sm flex items-center animate-fadeIn">
              <span className="text-gray-700">
                How are you today? I’m here to assist you with all your health
                needs.
              </span>
            </div> */}
            {/* <FloatButton.Group
              trigger="hover"
              type="primary"
              style={{ insetInlineEnd: 94 }}
              className="transform scale-125"
              icon={<MessageOutlined />}
            >
              <button
                onClick={showEbanqoWidget}
                className="rounded-lg bg-orange-500 w-24 p-3 mb-2 text-white hover:bg-orange-600"
              >
                💬 chat us
              </button>
              <button
                onClick={() => setIsChatOpen(true)}
                className="rounded-lg bg-red-500 w-24 p-3 text-white hover:bg-red-600 transition-all flex items-center justify-center"
              >
                <img
                  src={kobi}
                  alt="care"
                  className="h-6 w-6 rounded-lg mr-2"
                />
                Kobikuul
              </button>
            </FloatButton.Group> */}
          </div>
          {isChatOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6 relative">
                <button
                  onClick={() => setIsChatOpen(false)}
                  className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                >
                  ✖
                </button>
                <ChatInterface />
              </div>
            </div>
          )}
        </div>
      </header>

      <header
        className="w-full sm:hidden bg-cover h-[50vh] bg-center p-2"
        style={{ backgroundImage: `url(${img2})` }}
      >
        <Nav />

        <div className=" mt-3 ml-2">
          <p className=" text-black font-bold text-xl">Healthcare</p>
          <p className=" text-black font-bold text-xl">That Comes To</p>
          <p className=" text-black font-bold text-xl">You On Your </p>
          <p className=" text-black font-bold text-xl">Schedulesss</p>
        </div>
        <div className=" mt-10">
          <button
            onClick={() => navigate("/sign-up")}
            className="bg-[#FFCC08] px-8 py-2 cursor-pointer rounded-full"
          >
            Register
          </button>
        </div>
      </header>
    </>
  );
}
