import React, { useState } from "react";
import Header from "./components/header";
import Hero from "./components/hero";
// import ChatInterface from "../../../components/sections/chatai";
// import { FloatButton } from "antd";
import ChatInterface from "../../../components/sections/chatai";
// import kobi from "../../../assets/images/mascot.png";
// import { MessageFilled, MessageOutlined } from "@ant-design/icons";

// const showEbanqoWidget = () => {
//   const closed_class = "ebanqo-instant-chat-wrapper ebanqo-button-slide-out";
//   const open_class = "ebanqo-instant-chat-wrapper ebanqo-button-slide open";

//   const chatWrapper = document.querySelector(
//     ".ebanqo-widget-wrapper .ebanqo-instant-chat-wrapper"
//   );

//   if (chatWrapper) {
//     chatWrapper.setAttribute("class", open_class);
//     if (chatWrapper.className === open_class) {
//       chatWrapper.setAttribute("class", closed_class);
//     }

//     if (chatWrapper.className === closed_class) {
//       chatWrapper.setAttribute("class", open_class);
//     }
//   }
// };

export default function HomeTwo() {
  const [isChatOpen, setIsChatOpen] = useState(false);

  return (
    <div>
      <Header />
      <div className="flex justify-center w-full lg:hidden p-4">
        {/* <div className="border w-full"><ChatInterface /></div> */}
      </div>

      <Hero />
      <div className="md:hidden lg:hidden sm:hidded">
        {/* <div className="fixed bottom-5 right-14 bg-white shadow-md px-3 py-1 rounded-full border border-gray-300 text-sm flex items-center animate-fadeIn">
          <span className="text-gray-700">
            Hey! I’m here to assist you with all your health needs.
          </span>
        </div> */}
        {/* <FloatButton.Group
          trigger="hover"
          type="primary"
          style={{ right: 10, bottom: 24 }}
          icon={<MessageOutlined />}
        >
          <div className="flex flex-col items-end space-y-2">
            <button
              onClick={showEbanqoWidget}
              className="rounded-lg bg-orange-500 w-24 p-3 text-white hover:bg-orange-600 text-sm flex items-center justify-center"
            >
              <MessageFilled />
              <span className="ml-1">Chat</span>
            </button>

            <button
              onClick={() => setIsChatOpen(true)}
              className="rounded-lg bg-red-500 w-24 p-3 text-white hover:bg-red-600 transition-all flex items-center justify-center text-sm"
            >
              <img src={kobi} alt="care" className="h-6 w-6 rounded-lg mr-2" />
              <span>Kobikuul</span>
            </button>
          </div>
        </FloatButton.Group> */}
      </div>
      {isChatOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6 relative ">
            <button
              onClick={() => setIsChatOpen(false)}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
            >
              ✖
            </button>
            <ChatInterface />
          </div>
        </div>
      )}
    </div>
  );
}
