import kobi from "../../assets/images/mascot.png";
import React, { useState, useRef, useEffect } from "react";
import { Send, Loader2 } from "lucide-react";
import DataProvider from "../../services";
import { Link } from "react-router-dom";
import { FaPills, FaUserDoctor } from "react-icons/fa6";

interface Message {
  role: "user" | "assistant";
  content: string;
}

const ChatInterface: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isLoading]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    const userMessage: Message = {
      role: "user",
      content: inputMessage,
    };

    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setInputMessage("");
    setIsLoading(true);

    const payload = {
      message: inputMessage,
      previousMessages: messages,
    };

    try {
      const { data } = await DataProvider.requestSymptomExplanation(payload);
      const assistantMessage: Message = {
        role: "assistant",
        content: data.message || "No response received",
      };

      setMessages((prev) => [...prev, assistantMessage]);
    } catch (error) {
      const errorMessage: Message = {
        role: "assistant",
        content: "Sorry, there was an error processing your request.",
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const Message: React.FC<{ message: Message }> = ({ message }) => {
    const isUser = message.role === "user";
    return (
      <div className={`flex ${isUser ? "justify-end" : "justify-start"} mb-4`}>
        <div
          className={`max-w-[80%] md:max-w-[70%] px-3 py-2  rounded-lg ${
            isUser ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-800"
          }`}
        >
          <p className="text-xs whitespace-pre-wrap">{message.content}</p>
          {/* {!isUser && (
            <div className="w-full mt-1">
              <Link to="/sign-up" className="text-xs text-blue-500 underline">
                Speak to a Doctor
              </Link>
            </div>
          )} */}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="flex flex-col h-[60vh] bg-white rounded-md overflow-y-hidden w-full">
        <div className="bg-white border-b p-4 flex items-center gap-5">
          <span className="md:h-10 h-8 py-1 px-3 flex justify-center items-center bg-[#FFCC08] rounded-full">
            <img src={kobi} alt="care" className=" h-full rounded-l-lg" />
          </span>
          <div>
            <h1 className="text-xl font-semibold text-gray-800">Kobikuul</h1>
            <p className="text-xs">
              Chat with Kobikuul, your health information bot...
            </p>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4 space-y-4 flex flex-col">
          <div className="flex flex-col overflow-y-auto space-y-4">
            {messages.map((message, index) => (
              <Message key={index} message={message} />
            ))}
            {isLoading && (
              <div className="flex justify-start mb-4">
                <div className="bg-gray-200 p-3 rounded-lg text-xs">
                  💬 ....
                  {/* <Loader2 className="h-5 w-5 animate-spin text-gray-500" /> */}
                </div>
              </div>
            )}
            {/* <div ref={messagesEndRef} /> */}
          </div>
        </div>

        <form onSubmit={handleSubmit} className="border-t p-4">
          <div className="flex space-x-4">
            <input
              type="text"
              value={inputMessage}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInputMessage(e.target.value)
              }
              placeholder="Ask about a symptom, diseases..."
              className="flex-1 border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#FFCC08]"
            />
            <button
              type="submit"
              disabled={isLoading || !inputMessage.trim()}
              className="bg-[#FFCC08] text-white px-4 py-2 rounded-lg hover:bg-[#FFCC08] disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
            >
              {isLoading ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                <Send className="h-5 w-5" />
              )}
            </button>
          </div>
        </form>
      </div>
      <div className="flex flex-wrap flex-row w-full lg:mt-4 mb-3 mx-2 gap-4">
        <Link to="/sign-up" className="">
          <button className="px-4 py-2 flex text-sm gap-2 items-center rounded-full bg-red-400 text-white shadow-xl hover:bg-opacity-75">
            Speak to a Doctor <FaUserDoctor />
          </button>
        </Link>
        <Link to="/sign-up" className="">
          <button className="px-5 py-2 flex text-sm gap-2 items-center rounded-full bg-orange-500 text-white shadow-xl hover:bg-opacity-70">
            Upload Prescription <FaPills />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ChatInterface;
