import React, { useState, useEffect } from "react";
import { FaPills } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";

// Extend the Window interface to include ebanqo_widget
declare global {
  interface Window {
    ebanqo_widget: any;
  }
}

const ChatButton = () => {
  const [showOptions, setShowOptions] = useState(false);

  // Dynamically load eBanqo script if not already loaded
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.ebanqo.io/app.js";
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const hideFloatingButton = () => {
    const floating_button = Array.from(
      document.getElementsByClassName(
        "ebanqo-wd-chat-button ebanqo-trigger-btn ebq-widget__bounce"
      )
    )[0];
    (floating_button as HTMLElement).style.display = "none";

    const initialMessage = document.querySelector(
      ".initial-prompt-message-wrapper .ebanqo-button-slide-left"
    );
    if (initialMessage) {
      (initialMessage as HTMLElement).style.display = "none";
    }

    setTimeout(() => {
      const wrapper = document.querySelector(".initial-prompt-message-wrapper");
      if (wrapper) {
        wrapper.remove();
      }
    }, 100);
  };

  const showEbanqoWidget = () => {
    const closed_class = "ebanqo-instant-chat-wrapper ebanqo-button-slide-out";
    const open_class = "ebanqo-instant-chat-wrapper ebanqo-button-slide open";

    const chatWrapper = document.querySelector(
      ".ebanqo-widget-wrapper .ebanqo-instant-chat-wrapper"
    );

    if (chatWrapper) {
      chatWrapper.setAttribute("class", open_class);
      if (chatWrapper.className === open_class) {
        chatWrapper.setAttribute("class", closed_class);
      }

      if (chatWrapper.className === closed_class) {
        chatWrapper.setAttribute("class", open_class);
      }
    }
  };

  // Initialize eBanqo widget when called
  const initializeEbanqoWidget = () => {
    if (window.ebanqo_widget) {
      window.ebanqo_widget("init", {
        showChannelButtonsOnSignupForm: true,
        buttonColor: "#1a94ec",
        promptMessageDelay: 2000,
        promptMessage:
          "Hi there! Thanks for stopping by. How can I help you today?",
        instant_chat: {
          buttonColor: "#ED0D7F",
          logoUrl:
            "https://ebanqomessenger-public.s3.us-east-2.amazonaws.com/attachments/prod/webchat/poweredbymtnwhite.425023b05a934ab3aed1.png",
          url: "https://webchat.ebanqo.io/Mcare/",
          title: "Chat with Us",
          logoWidth: 80,
          logoHeight: 50,
          chatHeaderBackgroundColor: "#1a94ec",
          chatHeaderTextColor: "#fff",
        },
      });
    } else {
      console.error("eBanqo Widget not loaded");
    }
  };

  useEffect(() => {
    initializeEbanqoWidget();

    setTimeout(() => hideFloatingButton(), 1);
  }, []);

  return (
    <div className="border p-4 rounded-md bg-gray-50 ">
      <button onClick={() => setShowOptions(!showOptions)}>
        How can I help you?
      </button>

      {showOptions && (
        <div className="border ">
          <button
            onClick={() => alert("Opening Kobikuul AI chat...")}
            className="px-4 py-2 flex text-sm gap-2 items-center rounded-full bg-red-400 text-white shadow-xl hover:bg-opacity-75"
          >
            Chat with Kobikuul AI <FaUserDoctor />
          </button>
          <button
            onClick={showEbanqoWidget}
            className="px-5 py-2 flex text-sm gap-2 items-center rounded-full bg-orange-500 text-white shadow-xl hover:bg-opacity-70"
          >
            Chat with eBanqo <FaPills />
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatButton;
